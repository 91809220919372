import React from 'react'
import { useQuery } from 'react-query';
import { BLOGS_LIST_QUERY } from '../../utils/constants/globals';
import { BlogService } from '../../services/blog-services';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';

const BlogsListViewModel = () => {
    const {
        isLoading: isBlogsFetching,
        data: blogs,
    } = useQuery({
        queryKey: [BLOGS_LIST_QUERY],
        queryFn: () => BlogService.instance.getBlogsList(),
        refetchOnWindowFocus: false,
        enabled: true
    });

    const navigate = useNavigate();

    const handleOpenBlogPost = (id: number) => {
        navigate(Routes.blog + `/${id}`, {replace: false});
    }

    return {
        isLoading: false,
        blogs: blogs?.data?.data ?? [],
        handleOpenBlogPost,
    }
}

export default BlogsListViewModel