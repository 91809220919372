import {useState} from 'react';
import {IoChevronBack} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import ClassDetailsPageViewModel from '../../view-models/class-details-viewmodel';
import logo from "../../../assets/icons/link.svg"
import Layout from '../../components/common/layout';
import FullScreenLoader from '../../components/common/full-sreen-loader';
import HomeOptionsCard from '../../components/common/squareCard';
import { Routes } from '../../navigation/routes';

const TechniquePage = () => {

    const {
        isLoading,
        classesData,
        student,
        assignmentsCount,
        fitnessRecordsCount,
        StudentCertifications
    } = ClassDetailsPageViewModel();

   const startDate = new Date(student?.activeClassFeeData?.attributes?.fee_dates[0]?.startDate)
   const endDate = new Date(student?.activeClassFeeData?.attributes?.fee_dates[0]?.dueDate)

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Layout isProfileSwitchTabVisible={true}>
            {isLoading && <FullScreenLoader/>}
            <div className="flex p-2 justify-start flex-col">
                <div className="sm:block hidden">
                    <img src={logo} alt="logo" />
                </div>

                <div className={`flex gap-8 sm:pl-3 sm:pt-4`}>
                   
                        <IoChevronBack
                            className="text-blue-500 text-3xl mt-3 sm:block hidden"
                            onClick={handleBackClick}
                        />
                    
                    <span className="sm:text-4xl text-2xl sm:mt-1 font-semibold drop-shadow-lg">
                        {classesData?.attributes?.class_name}
                    </span>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center">
                <div className="w-full md:w-[90%] grid grid-cols-2 pt-4 pb-4 gap-4 ">
                    <div 
                        className={`h-[240px] w-full rounded-3xl flex flex-col p-4 col-span-2`} 
                        style={{ backgroundColor: '#FEDE67' }}
                        onClick={() => navigate(Routes.certifications)}
                    >
                        <div className='text-xl font-semibold'>Certifications</div>
                        <div className='grid grid-cols-3 items-center'>
                            <div className='flex gap-4 mt-6 items-center'>
                                <img 
                                    src='/assets/images/certificate.png'
                                    alt="fitness"
                                    className='h-12 w-12'
                                />
                                <div className='text-5xl font-semibold'>{StudentCertifications?.length}</div>
                            </div>
                            <div className='text-xs font-semibold mt-6 col-span-2'>Track your progress and certifications in each discipline! View your current certification level and see how many more levels you can achieve to reach the top.</div>
                        </div>
                        <div className='flex-grow flex items-center justify-center relative'> 
                            <div className='h-2 bg-blue-400 w-[40%] rounded-full animate-pulse'></div>
                            <div className='h-2 bg-slate-100 w-full rounded-full'></div>
                        </div>
                    </div>
                    <HomeOptionsCard
                        title='Assignments'
                        color='#94DAFB'
                        description='Review all your assignments, track deadlines, and monitor your progress to ensure timely completion.'
                        path={'/assignments'}
                        count={assignmentsCount}
                        icon='/assets/images/assignments.png'
                    />
                    <HomeOptionsCard
                        title='Attendance'
                        color='#FF9A62'
                        description='Review your attendance history, identify patterns, and stay informed about your presence in classes.'
                        path={'/attendance'}
                        count={Array.isArray(student?.activeClassAttendanceData) 
                            ? student?.activeClassAttendanceData?.filter(item => new Date(item?.attributes?.attendance_date) >= startDate && new Date(item?.attributes?.attendance_date) <= endDate).length 
                            : 0}
                        icon='/assets/images/attendance.png'
                    />
                    <HomeOptionsCard
                        title='Payments'
                        color='#C99FFF'
                        description='View your payment history, track upcoming due dates, and keep your records up-to-date.'
                        path={'/payments'}
                        count={
                            student?.activeClassFeeData?.attributes?.fee_dates
                                ?.length ?? 0
                        }
                        icon='/assets/images/fees-payment.png'
                    />
                    <HomeOptionsCard
                        title='Fitness'
                        color='#B6F26A'
                        description='Access all the fitness tests you&#39;ve performed, review your results, and view reports to monitor your improvement over time.'
                        path={'/fitness-tests'}
                        count={fitnessRecordsCount}
                        icon='/assets/images/fitness.png'
                    />
                </div>
            </div>
        </Layout>
    );
};

export default TechniquePage;
