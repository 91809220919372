import React, { useEffect, useState } from 'react'
import BlogPageViewModel from '../../view-models/blog-page-viewmodel';


const BlogPage = () => {
    const { 
        isLoading,
        blogPost,
    } = BlogPageViewModel();

    return (
        <div>
            <div
              dangerouslySetInnerHTML={{ __html: blogPost?.attributes?.pageData ?? '' }}
            />
        </div>
    )
}

export default BlogPage