import React from 'react'
import NavbarStatic from '../../components/navbar-static-pages'
import BlogsListViewModel from '../../view-models/blog-list-viewmodel';

const BlogsListPage = () => {
    const {
        isLoading,
        blogs,
        handleOpenBlogPost,
    } = BlogsListViewModel();

    return (
        <div className='h-screen flex flex-col'>
            <NavbarStatic />
            <hr className='border-dashed border-[1px] border-[#F8C954]'/>
            <div className='flex-grow bg-[#f8ca5487]'>
                {blogs.map((blog) => (
                    <div 
                        key={blog?.id}
                        className='p-4 cursor-pointer'
                        onClick={() => handleOpenBlogPost(blog?.id)}
                    >
                        <div className='bg-white rounded-lg p-4 shadow-md shadow-slate-200'>
                            <div className='flex flex-col'>
                                <div className='text-xl font-semibold'>{blog?.attributes?.title}</div>
                                <div className='text-gray-600'>{blog?.attributes?.metaTags}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BlogsListPage