import React, {useEffect, useState} from 'react';
import {EnrolmentPostDataWrapper} from '../../models/enrollment-data/enrolment-post-data-wrapper';
import {EnrollmentDataListWrapper} from '../../models/enrollment-data/enrollment-data-list-wrapper';
import {StudentEnrollmentService} from '../../services/student-enrolment-service';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../utils/redux/store';
import {ClassData} from '../../models/class/class-data';
import {ClassService} from '../../services/class-service';
import {AcademyService} from '../../services/academy-service';
import {
    ACADEMY_QUERY,
    CLAIMABLE_STUDENTS_LIST_FOR_ACADEMY,
    CLASS_QUERY,
    ENROLMENT_REQUESTS_LIST_QUERY,
    PARENT_DETAILS_QUERY,
} from '../../utils/constants/globals';
import { useNavigate } from 'react-router-dom';
import { UploadService } from '../../services/upload-service';
import { Routes } from '../navigation/routes';
import { useQueryParams } from '../../utils/helpers/helpers';
import { StudentService } from '../../services/student-service';
import { StudentData } from '../../models/student/student-data';
import { ParentService } from '../../services/parent-service';
import { setParentDetailsData } from '../../utils/redux/active-profile';
import { ParentDetailsData } from '../../models/parent-detail/parent-detail-data';

export interface formData {
    users: number[];
    academy: number[];
    classes: number[];
    firstName: string;
    middleName?: string;
    lastName?: string;
    gender?: string;
    dob?: string;
    bloodGroup?: string;
    medicalConditions?: string;
    address?: string;
    relationName?: string;
    relationNumber?: string;
    relationEmail?: string;
    relationWithStudent?: string;
    remarks?: string;
    imageUrl: string;
    imageId: number;
    school: string;
    grade: string;
    board: string;
}

const EnrolmentPageViewmodel = () => {
    const userState = useSelector((state: RootState) => state.user);
    const parentProfile = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] = useState(false);
    const profile = useSelector((state: RootState) => state.activeProfile);
    const [isLoading,setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch();

    const academyId = useQueryParams('academy');

    useEffect(() => {
        if (academyId) {
            setEnrolmentFormData(prev => ({...prev, academy: [Number(academyId)]}));
        }
    },[academyId])

    const initialFormData: formData = {
        users: [],
        academy: [],
        classes: [],
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        dob: '',
        bloodGroup: '',
        medicalConditions: '',
        address: '',
        relationName: '',
        relationNumber: '',
        relationEmail: '',
        relationWithStudent: '',
        remarks: '',
        imageUrl: '',
        imageId: 0,
        school: '',
        grade: '',
        board: '',
    };
    const [enrolmentFormData, setEnrolmentFormData] = useState<formData>(initialFormData);

    const postNewEnrolmentData = async (
        data: EnrolmentPostDataWrapper,
    ): Promise<EnrollmentDataListWrapper | undefined> => {
        const response =
            await StudentEnrollmentService.instance.postEnrolmentData(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewEnrolment,
        isLoading: isNewEnrolmentLoading,
        isSuccess: isNewEnrolmentAdded,
    } = useMutation(postNewEnrolmentData, {
        onSuccess: data => {
            queryClient.invalidateQueries(ENROLMENT_REQUESTS_LIST_QUERY)
            setFormSubmitted(true);
            setEnrolmentFormData(initialFormData);
        },
        onError: error => {
            alert('Failed to add Enrolment Form. Please try again!');
        },
    });

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const handleEnrolmentFormDetailsSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await addNewEnrolment({
            data: {
                academies: enrolmentFormData.academy,
                classes: enrolmentFormData.classes,
                // isSubmitted: true,
                email: userState.user?.email,
                users: academiesData?.data?.data.filter(item => item.id === enrolmentFormData.academy[0])[0].attributes.users.data.map(user => user.id),
                phoneNumber: Number(enrolmentFormData.relationNumber) ?? '',
                formData: {
                    firstName: enrolmentFormData.firstName,
                    middleName: enrolmentFormData.middleName,
                    lastName: enrolmentFormData.lastName,
                    gender: enrolmentFormData.gender,
                    dob: enrolmentFormData.dob,
                    bloodGroup: enrolmentFormData.bloodGroup,
                    medicalConditions: enrolmentFormData.medicalConditions,
                    address: enrolmentFormData.address,
                    relationName: enrolmentFormData.relationName,
                    relationNumber: enrolmentFormData.relationNumber,
                    relationEmail: enrolmentFormData.relationEmail,
                    relationWithStudent: enrolmentFormData.relationWithStudent,
                    school: enrolmentFormData.school,
                    grade: enrolmentFormData.grade,
                    board: enrolmentFormData.board,
                },
                remarks: enrolmentFormData.remarks,
                status: 'FORM_SUBMITTED',
                image: enrolmentFormData.imageId !== 0 ? enrolmentFormData.imageId : null,
            },
        });
        
    };

    const handleGoBack = () => {
        navigate(Routes.SelectStudentProfile, { replace: true });
    }

    const handleEnrolmentDataInputChange = (
        field: string,
        value: string | number[] | number | boolean | undefined,
    ) => {
        setEnrolmentFormData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };
    const {isSuccess: isAcademiesFetchSuccess, data: academiesData} = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademyService.instance.getAllAcademies.bind(this),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading: isClassFetching,
        data: classesData,
    } = useQuery({
        queryKey: [CLASS_QUERY, enrolmentFormData.academy],
        queryFn: ClassService.instance.getAllClasses.bind(
            this,
            enrolmentFormData.academy[0],
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const mapAcademiesToOptions = (): {value: number; label: string}[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data?.map(academy => {
                const {id, attributes} = academy;
                return {
                    value: id,
                    label: attributes.name,
                };
            });
        }
        return [];
    };

    const mapClassesToOptions = (): {value: number; label: string}[] | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data?.map((item: ClassData) => ({
                value: item.id,
                label: item?.attributes?.class_name,
            }));
        }
        return [];
    };

    const postImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);
            if (response.success) {
                if (response.data && response.data[0]) {
                    const imageData = response.data[0];
                    if (imageData.url && imageData.id) {
                        setEnrolmentFormData(prev => ({
                            ...prev,
                            imageId: imageData.id,
                            imageUrl: imageData.url
                        }));
                    }
                }
                return response.data;
            } else {
                throw new Error(response.error?.message || 'Upload failed');
            }
        } catch (error) {
            console.error(`Error in postImage: ${error}`);
            throw error;
        }
    };

    const {
        mutate: uploadImage,
        isLoading: isImagePosting,
        error: isImagePostingError,
    } = useMutation(postImage, {
        onSuccess: data => {
            queryClient.invalidateQueries();
        },
        onError: error => {
            alert('Failed to Upload')
        },
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
    
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to kilobytes
    
                if (fileSizeInKB > 10240) {
                    alert("Image size exceeds 10MB. Please choose a smaller file.");
                    return;
                }
            }
    
            const formData = new FormData();
    
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }
    
            const data = formData;
            uploadImage(data);
        }
    };

    const [claimableStudents, setClaimableStudents] = useState<StudentData[]>([]);

    const {
        isSuccess: isParentDetailsFetchSuccess,
        isLoading: isParentDetailsFetching,
        data: parentDetails,
    } = useQuery({
        queryKey: [PARENT_DETAILS_QUERY],
        queryFn: () => ParentService.instance.getParentUserDetails(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
        onSuccess(data) {
          dispatch(setParentDetailsData(data?.data?.data[0] ?? {} as ParentDetailsData))
        },
    });

    const {
        isSuccess: isclaimableStudentsOfAcademyFetchSuccess,
        isLoading: isclaimableStudentsOfAcademyFetching,
        data: claimableStudentsOfAcademy,
    } = useQuery({
        queryKey: [CLAIMABLE_STUDENTS_LIST_FOR_ACADEMY, profile.parentUserDetails],
        queryFn: () => StudentService.instance.getClaimableStudentsOfAcademy(userState?.user?.email ?? '',profile.parentUserDetails?.attributes?.phoneNumber,Number(academyId)),
        refetchOnWindowFocus: false,
        enabled: userState?.user?.email !== undefined && academyId !== null,
        onSuccess(data) {
          const claimedProfilesId = profile.allStudentsData.map(student => student.id)
          setClaimableStudents(data?.data?.data.filter(student => !claimedProfilesId.includes(student.id)) ?? [])
        },
    });

    const useBodyClass = (className: string, condition: boolean) => {
        useEffect(() => {
            if (condition) {
                document.body.classList.add(className);
            } else {
                document.body.classList.remove(className);
            }
        
            return () => {
                document.body.classList.remove(className);
            };
        }, [className, condition]);
    };

    const [index, setIndex] = useState<number>();
    useBodyClass('overflow-hidden', index !== undefined);

    const [selectedStudentsId, setSelectedStudentsId] = useState<number[]>([])

    const handleAddRemoveClaim = (id: number) => {
        if (selectedStudentsId.includes(id)) {
            setSelectedStudentsId(prev => prev.filter(studentId => studentId !== id));
        } else {
            setSelectedStudentsId(prev => [...prev, id]);
        }
        setIndex(undefined);
    };
    const [dobInput,setDobInput] = useState<string>('')

    const handleClaimStudentSubmit = () => {
        setIsLoading(true);
      
        const promises = selectedStudentsId.map(id => {
          const reqBody = {
            data: {
              parentLinkedTo: userState.user?.id,
              parentEmailId: userState.user?.email ?? null,
              parentContactNo: parentDetails?.data?.data[0]?.attributes?.phoneNumber ?? null,
            },
          };
      
          return StudentService.instance.updateStudent(id, reqBody);
        });
      
        Promise.all(promises)
          .then(responses => {
            setIsLoading(false);
            // Alert success if all updates are successful
            alert('All students claimed successfully!');
            navigate(Routes.SelectStudentProfile, { replace: true });
            // queryClient.invalidateQueries(USER_QUERY)
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
            // Handle the error as needed, e.g., show an error message to the user
            alert('An error occurred while claiming students.');
          });
    };    

    const [isClaimStudentModalClosed, setIsClaimStudentModalClosed] = useState<boolean>(false);

    useEffect(() => {
        if(profile.parentUserDetails){
            setEnrolmentFormData(prev => {
                return {
                    ...prev,
                    relationName: profile.parentUserDetails?.attributes?.firstName ?? '',
                    relationEmail:  userState.user?.email ?? '',
                    relationNumber: profile.parentUserDetails?.attributes?.phoneNumber === 0 ? '' : profile.parentUserDetails?.attributes?.phoneNumber.toString() ?? '',
                }
            })
        }
    },[profile.parentUserDetails])

    const [classSelectedForShowTimings, setClassSelectedForShowTimings] = useState<number>(0)

    const showClassTimings = (id: number) => {
        setClassSelectedForShowTimings(id)
    }

    const closeShowTimings = () => {
        setClassSelectedForShowTimings(0)
    }

    return {
        isLoading: isNewEnrolmentLoading || isclaimableStudentsOfAcademyFetching || isParentDetailsFetching || isLoading,
        handleEnrolmentDataInputChange,
        handleEnrolmentFormDetailsSubmit,
        mapClassesToOptions,
        mapAcademiesToOptions,
        allClasses: classesData?.data?.data ?? [],
        academiesData,
        enrolmentFormData,
        handleGoBack,
        formSubmitted,
        userState,
        parentProfile,
        handleFileChange,
        isImagePosting,
        isTermsAndConditionsOpen,
        setIsTermsAndConditionsOpen,
        academyId,
        claimableStudents,
        index,
        setIndex,
        selectedStudentsId,
        handleAddRemoveClaim,
        dobInput,
        setDobInput,
        handleClaimStudentSubmit,
        isClaimStudentModalClosed,
        setIsClaimStudentModalClosed,
        showClassTimings,
        classSelectedForShowTimings,
        closeShowTimings,
    };
};

export default EnrolmentPageViewmodel;
