import CryptoJS from 'crypto-js';
import { ACTIVE_CLASS_FEE_DATA_STORAGE_KEY, ACTIVE_CLASS_STORAGE_KEY, ACTIVE_STUDENT_STORAGE_KEY, ALL_CLASSES_DATA_STORAGE_KEY, ALL_STUDENTS_DATA_STORAGE_KEY, ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS, AUTH_SECRET, AUTH_TOKEN, DB_NAME, STORE_NAME, USER_STATE } from '../constants/globals';
import { User } from '../../models/user/user';
import { StudentData } from '../../models/student/student-data';
import { ClassData } from '../../models/class/class-data';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import { AttendanceAttributesWraper } from '../../models/attendance/attendance-attributes-wrapper';
import { useLocation } from 'react-router-dom';

export function getJWTToken(): string {
    const decryptedToken = CryptoJS.AES.decrypt(
        localStorage.getItem(AUTH_TOKEN) ?? '',
        AUTH_SECRET,
    );
    const originalToken = decryptedToken.toString(CryptoJS.enc.Utf8);
    return originalToken;
}

export const saveUserState = (userState: User | null) => {
    try {
        const serializedUserState = JSON.stringify(userState);
        const encryptedUserState = CryptoJS.AES.encrypt(serializedUserState, AUTH_SECRET).toString();
        localStorage.setItem(USER_STATE, encryptedUserState);
    } catch (error) {
        console.error('Error encrypting and saving user state to local storage:', error);
    }
};

export const loadUserState = (): User | null => {
    try {
        const encryptedUserState = localStorage.getItem(USER_STATE);
        if (encryptedUserState === null) {
            return null;
        }
        const bytes = CryptoJS.AES.decrypt(encryptedUserState, AUTH_SECRET);
        const decryptedUserState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as User;
        return decryptedUserState;
    } catch (error) {
        console.error('Error loading and decrypting user state from local storage:', error);
        return null;
    }
};

export function setJWTToken(jwt: string) {
    const encryptedToken = CryptoJS.AES.encrypt(jwt, AUTH_SECRET).toString();
    localStorage.setItem(AUTH_TOKEN, encryptedToken);
}

export const saveStudentToLocalStorage = (student: StudentData) => {
    localStorage.setItem(ACTIVE_STUDENT_STORAGE_KEY, JSON.stringify(student));
  };
  
  // Load student data from local storage
  export const loadStudentFromLocalStorage = (): StudentData | null => {
    const data = localStorage.getItem(ACTIVE_STUDENT_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  };
  
  // Save class data to local storage
  export const saveClassToLocalStorage = (classData: ClassData) => {
    localStorage.setItem(ACTIVE_CLASS_STORAGE_KEY, JSON.stringify(classData));
  };
  
  // Load class data from local storage
  export const loadClassFromLocalStorage = (): ClassData | null => {
    const data = localStorage.getItem(ACTIVE_CLASS_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  };

  export const saveClassFeeDataToLocalStorage = (feeData: FeePaymentData) => {
    localStorage.setItem(ACTIVE_CLASS_FEE_DATA_STORAGE_KEY, JSON.stringify(feeData));
  };
  
  // Load class data from local storage
  export const loadClassFeeDataFromLocalStorage = (): FeePaymentData | null => {
    const data = localStorage.getItem(ACTIVE_CLASS_FEE_DATA_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  };

  export const saveAllStudentsToLocalStorage = (StudentsLiat: StudentData[]) => {
    localStorage.setItem(ALL_STUDENTS_DATA_STORAGE_KEY, JSON.stringify(StudentsLiat));
  };
  
  // Load class data from local storage
  export const loadAllStudentsDataFromLocalStorage = (): ClassData[] | null => {
    const data = localStorage.getItem(ALL_STUDENTS_DATA_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  };

  export const saveAllClassesToLocalStorage = (ClassesList: ClassData[]) => {
    localStorage.setItem(ALL_CLASSES_DATA_STORAGE_KEY, JSON.stringify(ClassesList));
  };
  
  // Load class data from local storage
  export const loadAllClassesDataFromLocalStorage = (): ClassData[] | null => {
    const data = localStorage.getItem(ALL_CLASSES_DATA_STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  };

  export const saveAttendanceOfClassToLocalStorage = (ClassesList: AttendanceAttributesWraper[]) => {
    localStorage.setItem(ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS, JSON.stringify(ClassesList));
  };
  
  // Load class data from local storage
  export const loadAttendanceOfClassFromLocalStorage = (): AttendanceAttributesWraper[] | null => {
    const data = localStorage.getItem(ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS);
    return data ? JSON.parse(data) : null;
  };

export function getFormattedDate(inputDate: Date | string): string {
    const dateObject = new Date(inputDate);

    // Check if the date object is valid
    if (isNaN(dateObject.getTime())) {
        return "Invalid Date";
    }

    const day = dateObject.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject);
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
}


export async function handleLogout(){
  localStorage.removeItem(ACTIVE_CLASS_FEE_DATA_STORAGE_KEY)
  localStorage.removeItem(ACTIVE_CLASS_STORAGE_KEY)
  localStorage.removeItem(ACTIVE_STUDENT_STORAGE_KEY)
  localStorage.removeItem(ALL_CLASSES_DATA_STORAGE_KEY)
  localStorage.removeItem(ALL_STUDENTS_DATA_STORAGE_KEY)
  localStorage.removeItem(ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS)
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(USER_STATE)
  await indexedDB.deleteDatabase(DB_NAME);
}
 
export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export function formatTime(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleTimeString('en-US', options);
}

export function isPWAInstalled() {
  return window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
}

export function isServiceWorkerEnabled() {
  return 'serviceWorker' in navigator;
}

export const useQueryParams = (search: string) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return query.get(search);
};

export const useCurrentQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export function convertTo12HourFormat(time24: string) {
  const [hours, minutes] = time24.split(':');
  let period = 'AM';
  
  let hour = parseInt(hours, 10);
  if (hour >= 12) {
      period = 'PM';
      hour = hour > 12 ? hour - 12 : hour;
  } else if (hour === 0) {
      hour = 12;
  }

  return `${hour}:${minutes} ${period}`;
}
