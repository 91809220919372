import React from 'react'
import { useParams } from 'react-router-dom';
import { BlogService } from '../../services/blog-services';
import { useQuery } from 'react-query';
import { BLOGS_POST_QUERY } from '../../utils/constants/globals';

const BlogPageViewModel = () => {
    const { blogId } = useParams();
    
    const {
        isLoading: isBlogPostFetching,
        data: blogPost,
    } = useQuery({
        queryKey: [BLOGS_POST_QUERY, blogId],
        queryFn: () => BlogService.instance.getBlogPost(Number(blogId)),
        refetchOnWindowFocus: false,
        enabled: Number(blogId) > 0
    });
    

    return {
        isLoading: isBlogPostFetching,
        blogPost: blogPost?.data?.data,
    }
}

export default BlogPageViewModel