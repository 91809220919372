import { BlogData } from '../models/blog/blog-data';
import { BlogDataWrapper } from '../models/blog/blog-data-wrapper';
import { BlogListWrapper } from '../models/blog/blog-list-wrapper';
import { BlogPostData } from '../models/blog/blog-post-data';
import { invokeApi } from '../utils/helpers/invoke-api';
import ApiResponse from '../utils/types/api-response';

export class BlogService {
    private static _instance: BlogService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new BlogService());
    }

    public getBlogsList = async () => {
        return invokeApi<null, BlogListWrapper>({
            route: `blog-posts?filters[isLive]=true`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public getBlogPost = async (id: number) => {
        return invokeApi<null, BlogDataWrapper>({
            route: `blog-posts/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };
}